.BottomPanelMainDiv {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding-top: 0px;
}
.BottomPanelStoreList {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex: 1;
  width: calc(100vw - 120px);
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: center;
  scroll-behavior: smooth;
  height: 300px;
  padding: 0 8px;
}
.BottomPanelStoreList::-webkit-scrollbar {
  display: none;
}

.BottomPanelBackButton {
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  height: 80px;
  padding-right: 32px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.BottomPanelStoreListDivText {
  text-align: center;
  display: inline;
  opacity: 0.8;
  color: grey;
  font-family: "Segoe UI";
  font-size: 30px;
  line-height: 40px;
}
.BottomPanelLiveCart {
  box-sizing: border-box;
}

.BottomPanelCancelDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.BottomPanelCardList {
  background: #193c05;
  height: 160px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  flex-wrap: nowrap;
}

.BottomPanelCardList::-webkit-scrollbar {
  display: none;
}

.BottomPanelPayButtonDiv {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.BottomPanelButton {
  border-radius: 10px;
  background-image: linear-gradient(-90deg, #73ba36 0%, #429321 100%);
  display: flex;
  box-sizing: content-box;
  width: 100px;
  justify-content: center;
  align-items: center;
}

.BottomPanelAmountText {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 56px;
  min-width: 180px;
}

.BottomPanelPayButton {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin-right: 24px;
  height: 90%;
  min-width: 200px;
}
.BottomPanelPayButtonText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
  padding-bottom: 12px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0px;
  line-height: 60px;
}

.BottomPanelStoreCard {
  flex: 0 0 auto;
  margin: 8px;
}

.BottomPanelLiveCartText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
}

.PayText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
}

.LiveCartButtonsDiv {
  background-color: #091c01;
  padding: 0 20px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.RestartButton {
  float: right;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
}

.OrderModeSwitchDiv {
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1),
    inset 1px 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OrderModeSwitchDiv .ActiveDiv {
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
}

.ActiveText {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.InactiveText {
  opacity: 0.5;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .BottomPanelCardList {
    height: 90px;
  }

  .BottomPanelPayButtonDiv {
    height: 40px;
  }

  .fav_store_list {
  }

  .fav_store_list .BottomPanelStoreList {
    height: 140px;
  }

  .fav_store_list .BottomPanelStoreList > div {
    width: 120px !important;
    height: 120px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 16px 0px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .my-masonry-grid_column {
    width: 33% !important;
  }

  .trend_card_ph {
    width: 100% !important;
    padding: 5px 10px !important;
  }

  .trend_card {
    /* width: 100% !Important; */
  }

  .card_price > div {
    height: 20px;
    font-size: 16px;
  }

  .card_location {
    font-size: 11px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px;
  }

  .material-icons,
  .material-icons > svg {
    font-size: 14px !important;
  }

  .itemPrice {
    font-size: 14px !important;
    font-weight: 600;
  }

  .card_title {
    font-size: 14px !important;
    margin: 3px 0px;
  }

  .card_ingredient {
    font-size: 11px !important;
  }

  .my-masonry-grid_column img {
    /* width: 100% !important; */
  }

  .BottomPanelAmountText > p,
  .BottomPanelPayButtonDiv p {
    font-size: 18px !important;
  }

  .BottomPanelPayButtonDiv img {
    height: 24px !important;
    width: 24px !important;
  }

  .BottomPanelButton {
    height: 32px;
    margin-top: 2px;
  }

  .LiveCartButtonsDiv {
    padding: 0 10px;
    height: 40px;
  }

  .OrderModeSwitchDiv .ActiveDiv > p,
  .back_btn {
    font-size: 20px !important;
  }

  .OrderModeSwitchDiv > p {
    font-size: 12px;
  }

  .RestartButton img {
    height: 36px !important;
    margin-right: 15px;
    width: 105px !important;
  }
}
