.main_div {
  border-radius: 0;
  margin-bottom: 16px;
  padding: 8px;
}

.cart_store_div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cart_store_div > p {
  font-size: 24px;
  font-weight: bold;
  color: #777777;
}

.cart_store_div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart_sla_div {
  font-size: 20px;
}

.timer_icon {
  color: #777777;
  font-size: 20px;
  margin-left: 4px;
}

.cart_item_div {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}

.cart_item_div > p {
  font-size: 24px;
  text-transform: capitalize;
}

.item_name_text {
  width: 50%;
  display: flex;
  flex: 8;
}

.qty_picker {
  width: 30%;
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: flex-end;
}

.item_name_qty {
  /* font-weight: bold; */
  width: 20%;
  flex: 3;
  text-align: right;
  vertical-align: middle;
}

.item_name_qty > span {
  margin-right: 4px;
}

.qty_indicator {
  font-size: 24px;
  margin-right: 12px;
  font-weight: bold;
}
