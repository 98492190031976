.catImageCollapsed {
  height: 24px;
  width: 24px;
  object-fit: contain;
  background-color: transparent;
  filter: grayscale(100%);
  margin-right: 4;
  font-size: 16px;
}
.catImageCollapsed > p {
  font-size: 16px;
  margin-left: 6px;
}
.catImageExpanded {
  height: 48px;
  width: 48px;
  background-color: transparent;
  object-fit: contain;
  margin-right: 4;
  font-size: 16px;
}

.catImageExpanded > p {
  font-size: 16px;
  margin-left: 6px;
}

@media only screen and (max-width: 768px) {
  .catImageExpanded {
    -webkit-filter: CONTRAST(3);
    filter: CONTRAST(3);
  }
}
