/** HomeScreen **/

.HomeScreenMainDiv {
  overflow-y: scroll;
  width: 100vw;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  height: 95%;
  background-color: "transparent";
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

.HomeScreenSlider {
  width: 100%;
  height: 56vh;
}

.HomeScreenBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {

  .HomeScreenMainDiv {
    padding: 0 5px;
  }
}