.main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main_div {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex: 1;
  width: calc(100vw - 120px);
  flex-wrap: nowrap;
  align-items: center;
  scroll-behavior: smooth;
  height: 300px;
}
.main_div::-webkit-scrollbar {
  display: none;
}
.active_store {
  flex: 0 0 auto;
  height: 220px;
  width: 220px;
  margin-left: 8px;
  margin-right: 8px;
  transition: all 0.5s;
}

.inactive_store {
  flex: 0 0 auto;
  height: 160px;
  width: 160px;
  margin-right: 8px;
  margin-left: 8px;
  transition: all 0.5s;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
}

.arrow_icon_div {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow_icon {
  font-size: 40px;
  color: rgba(36, 25, 84, 0.7);
}
