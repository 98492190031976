/* GrabbnGoLogo.css */
.GrabbnGoLogoMainDiv {
  height: 80px;
  width: 100%;
  background-color: transparent;
  /* box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.3); */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
}

.GrabbnGoLogoImage {
  height: 60px;
  width: 900px;
  object-fit: contain;
}
