.OrderModeModalMianDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: whitesmoke;
  /* background-image: url("../../assets/Dine-in-1-bg.png"); */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 300px 16px;
}

.OrderModeModalHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.offerImageKiosk {
  width: 100vw;
  height: 200px;
  object-fit: contain;
  margin-top: 10vh;
}

.swal-content__input {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .OrderModeModalHeader ~ div > img {
    width: 400px !important;
  }
}

.deliveryCaptiontext {
  margin: 0;
  padding: 0;
  font-size: 32px;
  color: red;
  text-align: center;
}
.orderHereCaptiontext {
  margin-top: -5vh;
  padding: 0;
  font-size: 100px;
  color: #ff5500;
  text-align: center;
  
}
