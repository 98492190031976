.main_div {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ffffff;
  overflow-y: auto !important;
  letter-spacing: 1px;
  padding: 10px;
  position: relative;
  border-radius: 8px !important;
}

.main_content {
  display: flex;
  /* height: 100%; */
  position: relative;
  flex-direction: column;
}

.token_info_section {
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.gng_logo_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.info_div > p {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  background-color: tomato;
  border-radius: 6px;
  padding: 8px 4px;
  color: white;
}

.info_div > p > span {
  font-weight: 800;
}

.edit_button {
  /* color: white !important; */
  color: rgba(231, 66, 13, 1) !important;
  height: 40px !important;
  font-size: 16px !important;
  border: 1px solid rgba(231, 66, 13, 0.6) !important;
  margin-top: 12px !important;
}
.charges_container {
  width: 100%;
}

.charges_div {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}
.charge_label {
  font-size: 24px;
  text-transform: capitalize;
  line-height: 26px;
}
.charge_amount {
  font-size: 24px;
  text-transform: capitalize;
  line-height: 26px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.charge_amount > span {
  margin-right: 4px;
}

.token_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.token_text_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.token_text_div > p {
  font-size: 24px;
}
.token_number_text {
  font-weight: bold;
}

.token_image_div {
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 120px;
  border-radius: 6px;
  align-self: flex-end;
}
.token_first {
  color: white;
  background-color: #4a4a4a;
  width: 100%;
  text-align: center;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.token_second {
  color: black;
  width: 100%;
  text-align: center;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 28px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: #4a4a4a 1px solid;
  box-sizing: border-box;
  border-top: none;
}

.amount_details_div {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 28px;
  z-index: 2;
  background-color: white;
  /* height: 300px; */
}

.checkout_content {
  /* margin-bottom: 300px; */
}
