.CartDetailCardMainDiv {
  margin: auto;
  background-color: #ffffff;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
