.main_div {
  background-color: whitesmoke;
  padding: 12px;
  width: 100vw;
  overflow: hidden;
}
.header_div {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
}
.content_div {
  display: flex;
  height: 200px;
  width: 100%;
  overflow-x: auto;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
}
