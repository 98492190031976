.CheckoutScreenStoreCard {
  width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  min-height: 300px;
  border: solid black 1px;
  display: flex;
}
.mainDivCheckoutScreen {
  width: 100%;
  background-color: whitesmoke;
  height: 100vh;
  overflow: hidden;
  padding-top: 1vh;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20%;
  padding-right: 20%;
}
.selectPaymentTextDiv {
  border: 1px solid #555555;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  margin-bottom: 60px;
}
.selectPaymentText {
  width: 306px;
  color: #000000;
  font-family: "Segoe UI";
  font-size: 30px;
  line-height: 69px;
}
.paymentOptionsDiv {
  display: flex;
  flex-direction: row;
  padding-left: 3vw;
  padding-right: 3vw;
  justify-content: space-between;
}
.paytmDiv {
  height: 400px;
  width: 400px;
  border: 11px solid rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 1px 1px 10px 10px rgba(0, 0, 0, 0.5),
    0 2px 30px 0 rgba(0, 0, 0, 0.3);
}

.paytmTextDiv {
  height: 68px;
  width: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin-top: 24px;
}

.paymentOptionText {
  line-height: 68px;
  color: #000000;
  font-family: "Segoe UI";
  font-size: 30px;
  text-align: center;
}

.react-countdown-clock {
  margin: 16px auto;
  margin-left: 100px;
}

.separator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
}

@media only screen and (max-width: 768px) {
  .mainDivCheckoutScreen > div > img {
    width: auto !important;
    height: 170px !important;
  }

  .cc_info {
    padding: 0 !important;
  }

  .cc_info > div {
    background-image: url('../../assets/cc-guide.png') !important;
    width: 550px !important;
    background-size: 100%!important;
    background-repeat: no-repeat;
    margin: 0;
  }

  .cc_info > div > img {
    display: none !important;
  }

  .cc_ph {
    display: none !important;
  }

  .panel-ph {
    width: 80% !important;
  }

  .panel-ph p, .card_ph p, .separator > div > p  {
    font-size: 20px !important;
  }

  .card_ph {
    width: 100% !important;
    padding: 10px 0 !important;
  }

  .separator > div {
    width: 50px !important;
    height: 50px !important;
    font-size: 24px !important;
  }


}