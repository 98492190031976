.cartItemCard {
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 4px;
  min-width: 300px;
  margin-left: 4px;
  margin-right: 4px;
  flex: "0 0 auto";
  height: 120px;
  margin-bottom: 4px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartItemSelected {
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 4px;
  min-width: 300px;
  margin-left: 4px;
  margin-right: 4px;
  flex: "0 0 auto";
  height: 120px;
  margin-bottom: 4px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartItemProductDdetailsDiv {
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.cartItemCardFirstRow {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cartItemBottomRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}


@media only screen and (max-width: 768px) {
  .cartItemCard {
    height: 70px !Important;
    padding: 5px 10px;
    border-width: 1px;
  }

  .cartItemCard p {
    font-size: 12px !Important;
  }

  .cartItemCard .cartItemBottomRow {
    padding-bottom: 0 !important;
  }

  .cartItemProductDdetailsDiv {
    border-bottom: 1px dotted rgba(255,255,255,0.5) !important;
  }
}