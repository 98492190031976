.container {
  position: relative;
  text-align: center;
  background-image: url("../../assets/error-bg.png");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: fixed;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered {
  position: fixed;
  top: 20%;
  width: 80%;
  min-height: 45%;
  padding: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fdfdfd;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
}
