.StoreProductList::-webkit-scrollbar {
  display: none;
}
.StoreProductListMainDiv {
  display: flex;
  height: 95%;
  width: 100%;
  box-sizing: border-box;
}
.norows {
  position: fixed;
  top: 20%;
  width: 70%;
  min-height: 35%;
  padding: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fdfdfd;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
}

@media only screen and (max-width: 768px) {
  .StoreProductListMainDiv {
    display: flex;
  }

  .StoreProductList .my-masonry-grid .my-masonry-grid_column {
    width: 50% !important;
  }
  .store_list_panel {
    flex: 1%;
  }

  .store_list_panel > div {
    width: 100% !important;
    padding-left: 0 !important;
  }

  .store_list_panel > div > div {
    width: 96% !important;
    padding-left: 0 !important;
  }

  .store_list_panel > div > div > img {
    width: 36px;
    height: auto;
    margin-left: 8px;
  }

  .store_list_panel > div > div > p {
    font-size: 16px;
  }

  .StoreProductList {
    flex: 4;
    padding: 0 !important;
  }
}
