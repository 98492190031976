
@media only screen and (max-width: 768px) {
  .modal_ph {
    height:80% !important;
  }

  .modal_header .header_ph {
    padding: 10px !important;
  }

  .modal_ph .modal_body {
    flex: 9 !important;
  }

  .modal_header .header_ph .left_img {
    flex:1 !important;
    float: none !important;
    text-align: left;
  }

  .modal_header .header_ph .left_img img {
    width: 100px !important;
    height: 100px !important;
  }

  .modal_header .header_ph .right_content {
    flex:5 !important;
    float: none !important;
  }

  .modal_header .header_ph .right_content p {
    font-size: 16px !important;
  }

  .modal_note {
    font-size: 16px !important;
    padding-left: 10px !important;
  }

  .modal_ph .btn {
    font-size: 16px !important;
    height: 46px !important;
    width: auto !important;
  }

  .modal_ph .item_body {
    overflow-y: auto !important;
    height: 46vh !important;
  }

  .modal_ph .item_row p {
    font-size: 16px !important;

  }

  .modal_ph .tick_img img {
    width: 36px !important;
    height: 36px !important
  }
}