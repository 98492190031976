body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  /* user-select: none; */
  scroll-behavior: smooth;
}
p {
  margin: 0;
  padding: 0;
  text-transform: capitalize !important;
  letter-spacing: 0.5px;
}

button {
  border: none;
}

/** HomeScreen **/

.HomeScreenMainDiv {
  overflow-y: scroll;
  width: 100vw;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  height: 95%;
  background-color: "transparent";
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

.HomeScreenSlider {
  width: 100%;
  height: 56vh;
}

.HomeScreenBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: -webkit-flex;
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {

  .HomeScreenMainDiv {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 768px) {
  .modal_ph {
    height:80% !important;
  }

  .modal_header .header_ph {
    padding: 10px !important;
  }

  .modal_ph .modal_body {
    -webkit-flex: 9 1 !important;
            flex: 9 1 !important;
  }

  .modal_header .header_ph .left_img {
    -webkit-flex:1 1 !important;
            flex:1 1 !important;
    float: none !important;
    text-align: left;
  }

  .modal_header .header_ph .left_img img {
    width: 100px !important;
    height: 100px !important;
  }

  .modal_header .header_ph .right_content {
    -webkit-flex:5 1 !important;
            flex:5 1 !important;
    float: none !important;
  }

  .modal_header .header_ph .right_content p {
    font-size: 16px !important;
  }

  .modal_note {
    font-size: 16px !important;
    padding-left: 10px !important;
  }

  .modal_ph .btn {
    font-size: 16px !important;
    height: 46px !important;
    width: auto !important;
  }

  .modal_ph .item_body {
    overflow-y: auto !important;
    height: 46vh !important;
  }

  .modal_ph .item_row p {
    font-size: 16px !important;

  }

  .modal_ph .tick_img img {
    width: 36px !important;
    height: 36px !important
  }
}
.container {
  position: relative;
  text-align: center;
  background-image: url(/static/media/errorbackground.48b16e76.png);
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: fixed;
  object-fit: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.centered {
  position: fixed;
  top: 20%;
  width: 80%;
  min-height: 35%;
  padding: 120px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fdfdfd;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
}

.BottomPanelMainDiv {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding-top: 0px;
}
.BottomPanelStoreList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-x: auto;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: calc(100vw - 120px);
  box-sizing: border-box;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
  scroll-behavior: smooth;
  height: 300px;
  padding: 0 8px;
}
.BottomPanelStoreList::-webkit-scrollbar {
  display: none;
}

.BottomPanelBackButton {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-left: 32px;
  height: 80px;
  padding-right: 32px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}
.BottomPanelStoreListDivText {
  text-align: center;
  display: inline;
  opacity: 0.8;
  color: grey;
  font-family: "Segoe UI";
  font-size: 30px;
  line-height: 40px;
}
.BottomPanelLiveCart {
  box-sizing: border-box;
}

.BottomPanelCancelDiv {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
}

.BottomPanelCardList {
  background: #193c05;
  height: 160px;
  overflow-x: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.BottomPanelCardList::-webkit-scrollbar {
  display: none;
}

.BottomPanelPayButtonDiv {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
}

.BottomPanelButton {
  border-radius: 10px;
  background-image: linear-gradient(-90deg, #73ba36 0%, #429321 100%);
  display: -webkit-flex;
  display: flex;
  box-sizing: content-box;
  width: 100px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.BottomPanelAmountText {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 56px;
  min-width: 180px;
}

.BottomPanelPayButton {
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-sizing: border-box;
  margin-right: 24px;
  height: 90%;
  min-width: 200px;
}
.BottomPanelPayButtonText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
  padding-bottom: 12px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0px;
  line-height: 60px;
}

.BottomPanelStoreCard {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 8px;
}

.BottomPanelLiveCartText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
}

.PayText {
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
}

.LiveCartButtonsDiv {
  background-color: #091c01;
  padding: 0 20px;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.RestartButton {
  float: right;
  box-sizing: border-box;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.OrderModeSwitchDiv {
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1),
    inset 1px 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.OrderModeSwitchDiv .ActiveDiv {
  height: 100%;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  text-align: center;
}

.ActiveText {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.InactiveText {
  opacity: 0.5;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .BottomPanelCardList {
    height: 90px;
  }

  .BottomPanelPayButtonDiv {
    height: 40px;
  }

  .fav_store_list {
  }

  .fav_store_list .BottomPanelStoreList {
    height: 140px;
  }

  .fav_store_list .BottomPanelStoreList > div {
    width: 120px !important;
    height: 120px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 16px 0px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .my-masonry-grid_column {
    width: 33% !important;
  }

  .trend_card_ph {
    width: 100% !important;
    padding: 5px 10px !important;
  }

  .trend_card {
    /* width: 100% !Important; */
  }

  .card_price > div {
    height: 20px;
    font-size: 16px;
  }

  .card_location {
    font-size: 11px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px;
  }

  .material-icons,
  .material-icons > svg {
    font-size: 14px !important;
  }

  .itemPrice {
    font-size: 14px !important;
    font-weight: 600;
  }

  .card_title {
    font-size: 14px !important;
    margin: 3px 0px;
  }

  .card_ingredient {
    font-size: 11px !important;
  }

  .my-masonry-grid_column img {
    /* width: 100% !important; */
  }

  .BottomPanelAmountText > p,
  .BottomPanelPayButtonDiv p {
    font-size: 18px !important;
  }

  .BottomPanelPayButtonDiv img {
    height: 24px !important;
    width: 24px !important;
  }

  .BottomPanelButton {
    height: 32px;
    margin-top: 2px;
  }

  .LiveCartButtonsDiv {
    padding: 0 10px;
    height: 40px;
  }

  .OrderModeSwitchDiv .ActiveDiv > p,
  .back_btn {
    font-size: 20px !important;
  }

  .OrderModeSwitchDiv > p {
    font-size: 12px;
  }

  .RestartButton img {
    height: 36px !important;
    margin-right: 15px;
    width: 105px !important;
  }
}

.CartDetailCardMainDiv {
  margin: auto;
  background-color: #ffffff;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cartItemCard {
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 4px;
  min-width: 300px;
  margin-left: 4px;
  margin-right: 4px;
  -webkit-flex: "0 0 auto" 1;
          flex: "0 0 auto" 1;
  height: 120px;
  margin-bottom: 4px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cartItemSelected {
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 4px;
  min-width: 300px;
  margin-left: 4px;
  margin-right: 4px;
  -webkit-flex: "0 0 auto" 1;
          flex: "0 0 auto" 1;
  height: 120px;
  margin-bottom: 4px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cartItemProductDdetailsDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 2 1;
          flex: 2 1;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.cartItemCardFirstRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.cartItemBottomRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 8px;
}


@media only screen and (max-width: 768px) {
  .cartItemCard {
    height: 70px !Important;
    padding: 5px 10px;
    border-width: 1px;
  }

  .cartItemCard p {
    font-size: 12px !Important;
  }

  .cartItemCard .cartItemBottomRow {
    padding-bottom: 0 !important;
  }

  .cartItemProductDdetailsDiv {
    border-bottom: 1px dotted rgba(255,255,255,0.5) !important;
  }
}
.LiveCart_live_cart_main_div__3BpOf {
  box-sizing: border-box;
  color: white;
}
.LiveCart_cart_header__IGI4t {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.LiveCart_your_cart_text__YQWLJ {
  font-size: 30px;
  color: "#FFFFFF";
  margin-left: 12px;
}

.LiveCart_count_badge__1tysr {
  padding: 2px;
}

.LiveCart_cart_icon__2zQP- {
  font-size: 32px;
  color: "#FFFFFF";
  margin-left: 8px;
}
.LiveCart_cart_amount_text__e7IN3 {
  color: "#FFFFFF";
  font-size: 30px;
  font-weight: "600";
}
.LiveCart_cart_amount_heading__2VoMt {
  font-weight: "300";
  margin-right: 4px;
}

.LiveCart_pay_button__-ig4V {
  font-size: 30px;
  color: "#FFFFFF";
  text-shadow: 0 2px 2px #4a4a4a;
  font-weight: "bold";
}

.LiveCart_back_button_div__1Pnto {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100px;
  color: "#F0F8FE";
  -webkit-align-items: center;
          align-items: center;
}

.LiveCart_back_button__3_F3Q {
  color: "#F0F8FE";
  margin-left: 8px;
  font-size: 32px;
}

.LiveCart_pay_button_div__3l39g {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
}

.LiveCart_live_cart_pay_button__21tBT {
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-sizing: border-box;
  margin-right: 24px;
  height: 90%;
  min-width: 200px;
}
.LiveCart_pay_div_amount_text__1Tuz7 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 56px;
  min-width: 180px;
}

.LiveCart_live_cart_pay_button_div__FDpkk {
  border-radius: 10px;
  background-image: linear-gradient(-90deg, #73ba36 0%, #429321 100%);
  display: -webkit-flex;
  display: flex;
  box-sizing: content-box;
  width: 100px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.LiveCart_bottom_panel_card_list__DnKUb {
  background: #193c05;
  height: 160px;
  overflow-x: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.LiveCart_bottom_panel_card_list__DnKUb::-webkit-scrollbar {
  display: none;
}

.LiveCart_live_cart_button_div__2G8p2 {
  background-color: #091c01;
  padding: 0 20px;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.LiveCart_order_mode_switch_div__1zBrq {
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1),
    inset 1px 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.LiveCart_order_mode_switch_div__1zBrq > .LiveCart_active_div__3hdGb {
  height: 100%;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  text-align: center;
}

.LiveCart_active_text__2aDFi {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.LiveCart_inactive_text__lnjzH {
  opacity: 0.5;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
.LiveCart_restart_button__7a_KL {
  float: right;
  box-sizing: border-box;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .LiveCart_BottomPanelCardList__2jF_J {
    height: 90px;
  }

  .LiveCart_BottomPanelPayButtonDiv__2O0vX {
    height: 40px;
  }

  .LiveCart_fav_store_list__2KuQa {
  }

  .LiveCart_fav_store_list__2KuQa .LiveCart_BottomPanelStoreList__1M9m2 {
    height: 140px;
  }

  .LiveCart_fav_store_list__2KuQa .LiveCart_BottomPanelStoreList__1M9m2 > div {
    width: 120px !important;
    height: 120px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 16px 0px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .LiveCart_my-masonry-grid_column__2BJKS {
    width: 33% !important;
  }

  .LiveCart_trend_card_ph__2I5r7 {
    width: 100% !important;
    padding: 5px 10px !important;
  }

  .LiveCart_trend_card__4XPz6 {
    /* width: 100% !Important; */
  }

  .LiveCart_card_price__2vqMy > div {
    height: 20px;
    font-size: 16px;
  }

  .LiveCart_card_location__3ahGp {
    font-size: 11px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px;
  }

  .LiveCart_material-icons__U6iku,
  .LiveCart_material-icons__U6iku > svg {
    font-size: 14px !important;
  }

  .LiveCart_itemPrice__2HWNH {
    font-size: 14px !important;
    font-weight: 600;
  }

  .LiveCart_card_title__2Hbks {
    font-size: 14px !important;
    margin: 3px 0px;
  }

  .LiveCart_card_ingredient__TXsL2 {
    font-size: 11px !important;
  }

  .LiveCart_my-masonry-grid_column__2BJKS img {
    /* width: 100% !important; */
  }

  .LiveCart_BottomPanelAmountText__uZ7Fq > p,
  .LiveCart_BottomPanelPayButtonDiv__2O0vX p {
    font-size: 18px !important;
  }

  .LiveCart_BottomPanelPayButtonDiv__2O0vX img {
    height: 24px !important;
    width: 24px !important;
  }

  .LiveCart_BottomPanelButton__oVuAz {
    height: 32px;
    margin-top: 2px;
  }

  .LiveCart_LiveCartButtonsDiv__3VYA2 {
    padding: 0 10px;
    height: 40px;
  }

  .LiveCart_OrderModeSwitchDiv__3gP_S .LiveCart_ActiveDiv__RN5lU > p,
  .LiveCart_back_btn__2DDVc {
    font-size: 20px !important;
  }

  .LiveCart_OrderModeSwitchDiv__3gP_S > p {
    font-size: 12px;
  }

  .LiveCart_RestartButton__3qOXT img {
    height: 36px !important;
    margin-right: 15px;
    width: 105px !important;
  }
}

.StoreSelector_main_container__1bbds {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.StoreSelector_main_div__1_Xcc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-x: auto;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: calc(100vw - 120px);
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
  scroll-behavior: smooth;
  height: 300px;
}
.StoreSelector_main_div__1_Xcc::-webkit-scrollbar {
  display: none;
}
.StoreSelector_active_store__1t3wm {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 220px;
  width: 220px;
  margin-left: 8px;
  margin-right: 8px;
  transition: all 0.5s;
}

.StoreSelector_inactive_store__32Sgn {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 160px;
  width: 160px;
  margin-right: 8px;
  margin-left: 8px;
  transition: all 0.5s;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
}

.StoreSelector_arrow_icon_div__2CNea {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.StoreSelector_arrow_icon__1q5e_ {
  font-size: 40px;
  color: rgba(36, 25, 84, 0.7);
}

.StoreCardMainCard {
  height: 100%;
  width: 100%;
  margin-right: 8px;
  box-sizing: border-box;
  background: white;
  border: none;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}


.store_card_name_text{
  text-align: center;

}
.StoreProductList::-webkit-scrollbar {
  display: none;
}
.StoreProductListMainDiv {
  display: -webkit-flex;
  display: flex;
  height: 95%;
  width: 100%;
  box-sizing: border-box;
}
.norows {
  position: fixed;
  top: 20%;
  width: 70%;
  min-height: 35%;
  padding: 120px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fdfdfd;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
}

@media only screen and (max-width: 768px) {
  .StoreProductListMainDiv {
    display: -webkit-flex;
    display: flex;
  }

  .StoreProductList .my-masonry-grid .my-masonry-grid_column {
    width: 50% !important;
  }
  .store_list_panel {
    -webkit-flex: 1% 1;
            flex: 1% 1;
  }

  .store_list_panel > div {
    width: 100% !important;
    padding-left: 0 !important;
  }

  .store_list_panel > div > div {
    width: 96% !important;
    padding-left: 0 !important;
  }

  .store_list_panel > div > div > img {
    width: 36px;
    height: auto;
    margin-left: 8px;
  }

  .store_list_panel > div > div > p {
    font-size: 16px;
  }

  .StoreProductList {
    -webkit-flex: 4 1;
            flex: 4 1;
    padding: 0 !important;
  }
}

.catImageCollapsed {
  height: 24px;
  width: 24px;
  object-fit: contain;
  background-color: transparent;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin-right: 4;
  font-size: 16px;
}
.catImageCollapsed > p {
  font-size: 16px;
  margin-left: 6px;
}
.catImageExpanded {
  height: 48px;
  width: 48px;
  background-color: transparent;
  object-fit: contain;
  margin-right: 4;
  font-size: 16px;
}

.catImageExpanded > p {
  font-size: 16px;
  margin-left: 6px;
}

@media only screen and (max-width: 768px) {
  .catImageExpanded {
    -webkit-filter: CONTRAST(3);
    filter: CONTRAST(3);
  }
}

.CheckoutScreenStoreCard {
  width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  min-height: 300px;
  border: solid black 1px;
  display: -webkit-flex;
  display: flex;
}
.mainDivCheckoutScreen {
  width: 100%;
  background-color: whitesmoke;
  height: 100vh;
  overflow: hidden;
  padding-top: 1vh;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20%;
  padding-right: 20%;
}
.selectPaymentTextDiv {
  border: 1px solid #555555;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  margin-bottom: 60px;
}
.selectPaymentText {
  width: 306px;
  color: #000000;
  font-family: "Segoe UI";
  font-size: 30px;
  line-height: 69px;
}
.paymentOptionsDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-left: 3vw;
  padding-right: 3vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.paytmDiv {
  height: 400px;
  width: 400px;
  border: 11px solid rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 1px 1px 10px 10px rgba(0, 0, 0, 0.5),
    0 2px 30px 0 rgba(0, 0, 0, 0.3);
}

.paytmTextDiv {
  height: 68px;
  width: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin-top: 24px;
}

.paymentOptionText {
  line-height: 68px;
  color: #000000;
  font-family: "Segoe UI";
  font-size: 30px;
  text-align: center;
}

.react-countdown-clock {
  margin: 16px auto;
  margin-left: 100px;
}

.separator {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
}

@media only screen and (max-width: 768px) {
  .mainDivCheckoutScreen > div > img {
    width: auto !important;
    height: 170px !important;
  }

  .cc_info {
    padding: 0 !important;
  }

  .cc_info > div {
    background-image: url(/static/media/cc-guide.01aee1bd.png) !important;
    width: 550px !important;
    background-size: 100%!important;
    background-repeat: no-repeat;
    margin: 0;
  }

  .cc_info > div > img {
    display: none !important;
  }

  .cc_ph {
    display: none !important;
  }

  .panel-ph {
    width: 80% !important;
  }

  .panel-ph p, .card_ph p, .separator > div > p  {
    font-size: 20px !important;
  }

  .card_ph {
    width: 100% !important;
    padding: 10px 0 !important;
  }

  .separator > div {
    width: 50px !important;
    height: 50px !important;
    font-size: 24px !important;
  }


}
.CheckoutRender_main_div__1X8b8 {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ffffff;
  overflow-y: auto !important;
  letter-spacing: 1px;
  padding: 10px;
  position: relative;
  border-radius: 8px !important;
}

.CheckoutRender_main_content__3xLga {
  display: -webkit-flex;
  display: flex;
  /* height: 100%; */
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.CheckoutRender_token_info_section__2JkRa {
  padding: 16px;
  margin-bottom: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.CheckoutRender_gng_logo_div__1YoRn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.CheckoutRender_info_div__yENnP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 16px;
}
.CheckoutRender_info_div__yENnP > p {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  background-color: tomato;
  border-radius: 6px;
  padding: 8px 4px;
  color: white;
}

.CheckoutRender_info_div__yENnP > p > span {
  font-weight: 800;
}

.CheckoutRender_edit_button__15UUp {
  /* color: white !important; */
  color: rgba(231, 66, 13, 1) !important;
  height: 40px !important;
  font-size: 16px !important;
  border: 1px solid rgba(231, 66, 13, 0.6) !important;
  margin-top: 12px !important;
}
.CheckoutRender_charges_container__3ReGF {
  width: 100%;
}

.CheckoutRender_charges_div__1jGmd {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 16px;
  width: 100%;
}
.CheckoutRender_charge_label__1WgCD {
  font-size: 24px;
  text-transform: capitalize;
  line-height: 26px;
}
.CheckoutRender_charge_amount__N2S4y {
  font-size: 24px;
  text-transform: capitalize;
  line-height: 26px;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.CheckoutRender_charge_amount__N2S4y > span {
  margin-right: 4px;
}

.CheckoutRender_token_info__3zNut {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.CheckoutRender_token_text_div__3cCjm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 24px;
}

.CheckoutRender_token_text_div__3cCjm > p {
  font-size: 24px;
}
.CheckoutRender_token_number_text__36bmV {
  font-weight: bold;
}

.CheckoutRender_token_image_div__3VAm8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80px;
  width: 120px;
  border-radius: 6px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.CheckoutRender_token_first__2jDXZ {
  color: white;
  background-color: #4a4a4a;
  width: 100%;
  text-align: center;
  height: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: 600;
  font-size: 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.CheckoutRender_token_second__2Nj2E {
  color: black;
  width: 100%;
  text-align: center;
  height: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: 600;
  font-size: 28px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: #4a4a4a 1px solid;
  box-sizing: border-box;
  border-top: none;
}

.CheckoutRender_amount_details_div__hjDxO {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 28px;
  z-index: 2;
  background-color: white;
  /* height: 300px; */
}

.CheckoutRender_checkout_content__1qA0S {
  /* margin-bottom: 300px; */
}

.CheckoutCart_main_div__36EUI {
  border-radius: 0;
  margin-bottom: 16px;
  padding: 8px;
}

.CheckoutCart_cart_store_div__1YSVH {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 8px;
}

.CheckoutCart_cart_store_div__1YSVH > p {
  font-size: 24px;
  font-weight: bold;
  color: #777777;
}

.CheckoutCart_cart_store_div__1YSVH > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.CheckoutCart_cart_sla_div__3rm5C {
  font-size: 20px;
}

.CheckoutCart_timer_icon__U1tfR {
  color: #777777;
  font-size: 20px;
  margin-left: 4px;
}

.CheckoutCart_cart_item_div__3OSGd {
  padding: 4px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 44px;
}

.CheckoutCart_cart_item_div__3OSGd > p {
  font-size: 24px;
  text-transform: capitalize;
}

.CheckoutCart_item_name_text__oMzfd {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 8 1;
          flex: 8 1;
}

.CheckoutCart_qty_picker__2p5K7 {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 4 1;
          flex: 4 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.CheckoutCart_item_name_qty__WpEOl {
  /* font-weight: bold; */
  width: 20%;
  -webkit-flex: 3 1;
          flex: 3 1;
  text-align: right;
  vertical-align: middle;
}

.CheckoutCart_item_name_qty__WpEOl > span {
  margin-right: 4px;
}

.CheckoutCart_qty_indicator__3WGOr {
  font-size: 24px;
  margin-right: 12px;
  font-weight: bold;
}

.QunatityPickerMainDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-radius: 8px;
  padding: 0;
  height: 35px;
  width: 120px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.QunatityPickerMainDiv div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: "Open Sans";
  font-size: 27px;
}

.QunatityPickerButtonLeftDiv {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.QunatityPickerButtonRightDiv {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* GrabbnGoLogo.css */
.GrabbnGoLogoMainDiv {
  height: 80px;
  width: 100%;
  background-color: transparent;
  /* box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.3); */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  margin: auto;
}

.GrabbnGoLogoImage {
  height: 60px;
  width: 900px;
  object-fit: contain;
}

.bg_input_modal {
  width: 80vw;
  margin: auto;
  background-color: white;
  border-radius: 16px;
  padding: 30px 16px;
  position: fixed;
  top: 25%;
  margin: 0 10vw;
}
.cust_text_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.title_text {
  font-size: 32px;
  letter-spacing: 1px;
}
.textinput_default {
  width: 80%;
  border: #1c4995;
  margin: auto;
  text-align: center;
  font-size: 28px;
  letter-spacing: 1px;
}

.keyboard1 {
  /* background: rgba(0, 0, 0, 0.86) !important; */
  max-width: 800px;
  min-height: 300px;
}

.simple-keyboard.myTheme .hg-row .hg-button {
  /* color: white; */
  -webkit-animation: none;
          animation: none;
  /* background: rgba(0, 0, 0, 0.86) !important; */
  font-weight: bold;
  font-size: 24px;
  height: 55px;
  font-family: Open Sans;
}

.hg-button-bksp {
  -webkit-flex: 2 1 !important;
          flex: 2 1 !important;
}

.hg-functionBtn.hg-button-enter {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 500px;
}

.hg-functionBtn.hg-button-space {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 500px;
}
.gate-list {
  padding: auto;
}

.gate-list li {
  width: 100%;
  list-style-type: none;
  float: left;
  width: 20%;
  height: 60px;
  border: 1px dotted #ccc;
  padding: 12px;
  font-size: 24px;
  background-color: #f55000;
  color: white;
  font-weight: bold;
}

.gate-sel-div {
  margin-right: 4%;
  margin-bottom: 3%;
}

.ApplyCode_main__2Lmo- {
  margin-top: 8px;
}
.ApplyCode_input_div__30QWd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 90%;
  border: 1px solid #ff5500;
  border-radius: 4px;
}
.ApplyCode_container_div__2fwtK{
  /*display: flex;*/
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 90%;
  margin-left: 5%;
}
.ApplyCode_input__2D0_g {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-left: 12px;
}

.ApplyCode_apply_button__2B97s {
  border-radius: 0 !important;
  margin-top: 10% !important;
  width: 82%;
  height: 60px !important;
}

.CouponInputKeyboardDiv {
  width: 600px;
}
.CouponInputKeyboardDiv .simple-keyboard {
  max-width: 600px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.InputBox {
  height: 70px;
  width: 500px;
  border: 2px solid #979797;
  border-radius: 10px;
  margin: 0 50px;
  box-sizing: border-box;
  font-size: 28px;
  padding: 0 20px;
}
.CouponInputKeyboardDiv .myTheme1 {
  background-color: transparent;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.CouponInputKeyboardDiv .simple-keyboard.myTheme1 .hg-row {
  margin: 0;
}
.CouponInputKeyboardDiv .simple-keyboard.myTheme1 .hg-row .hg-button {
  height: 80px;
  width: 60px;
  color: white;
  background: rgba(0, 0, 0, 0.86);
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}

.TokenScreenMainContainer {
  background-image: url(/static/media/userdetails.cbd16ba7.png);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-bottom: 12px;
  box-sizing: border-box;
}

.TokenScreenTickContainer {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.phoneText {
  border-radius: 4px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.TokenScreenTokenCardContainer {
  height: 60vh;
  width: 800px;
  margin: auto;
  margin-top: 150px;
  background-color: #ffffff;
  opacity: 0.8;
  overflow: hidden;
  box-sizing: border-box;
}

.TokenCardInfoSection {
  border-bottom: 2px dashed #666666;
  padding: 16px;
  margin-bottom: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.TokenCardInfoSectionLogo {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.TokenCardInfoSectionTokenNo {
  display: -webkit-flex;
  display: flex;
  height: 100px;
  width: 126px;
  border-radius: 6px;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: #666666 1px solid;
}

.TokenCardInfoSectionTokenNo .TokenTextDiv {
  background-color: #4a4a4a;
  text-align: center;
  height: 32;
}

.TokenCardOrderDetailSec {
  /* padding-left: 24px 8px; */
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  overflow: auto;
  height: 48%;
}

.TokenCardOrderItem {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.TokenCardOrderItem .Qty {
  -webkit-flex: 2 1;
          flex: 2 1;
}
.TokenCardOrderItem .NameDetails {
  -webkit-flex: 13 1;
          flex: 13 1;
}
.TokenCardOrderItem .PrepTimeInfo {
  -webkit-flex: 3 1;
          flex: 3 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.TokenCardOrderItem .PriceInfo {
  -webkit-flex: 4 1;
          flex: 4 1;
}

.TokenCardExtraChargeDiv {
  overflow: auto;
  padding: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: #666666 1px dashed;
}

.TokenCardTotalDiv {
  padding: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.TokenCardPaymentModeDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border-top: #666666 1px solid;
  padding: 16px;
}

@media only screen and (max-width: 768px) {
  .TokenScreenTickContainer {
    margin: 0 !important;
  }

  .TokenScreenTokenCardContainer {
    width: 90% !important;
    margin: 0 auto !important;
    overflow: auto;
  }
}

.checkout_container_token_screen {
  height: 60vh;
  width: 850px;
  margin: auto;
  margin-top: 32px;
  opacity: 0.8;
}

.container {
  position: relative;
  text-align: center;
  background-image: url(/static/media/error-bg.29457301.png);
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: fixed;
  object-fit: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.centered {
  position: fixed;
  top: 20%;
  width: 80%;
  min-height: 45%;
  padding: 120px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fdfdfd;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
}

.FeedBack_main_div__O62iE {
  background-color: whitesmoke;
  width: 850px;
  padding: 24px;
  text-align: center;
  margin: 24px auto 0;
  opacity: 0.7;
}
.FeedBack_rating_div__1C8pm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 48px;
}
.FeedBack_main_div__O62iE > p {
  font-size: 28px;
}

.FeedBack_modal_body__3vT8Q {
  position: fixed;
  background-color: white;
  top: 40%;
  width: 50vw;
  left: 25vw;
  right: 25vw;
  text-align: center;
  border-radius: 12px;
}
.FeedBack_modal_body__3vT8Q > p {
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 32px;
  margin-top: 32px;
  text-transform: none !important;
}

.FeedBack_submit_button__2w5hT {
  background-color: #f55000 !important;
  width: 200px !important;
  margin-bottom: 32px !important;
}

.mainDiv {
  /* The image used */
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.8;
  /* Preserve aspet ratio */
  width: 100%;
  height: 100%;
  padding-top: 200px;
}
.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.startButton {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0px;
  height: 140px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #f9a825 30%, #ff9800 90%);
}
.startButtonText {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: white;
}

.loginModal {
  background-color: whitesmoke;
  padding-bottom: 32px;
  width: 50%;
  z-index: 100;
  box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2);
  position: absolute;
  text-align: center;
  top: 20%;
}
.loginModalButton {
  margin-top: 16px;
  width: 50%;
  padding: 12px;
  background: linear-gradient(45deg, #f9a825 30%, #ff9800 90%);
  color: white;
  font-size: 24px;
  font-weight: 700;
}
.modalTitleDiv {
  border-bottom: 1px dashed gray;
}
.ControlDots {
  display: -webkit-flex !important;
  display: flex !important;
  box-sizing: border-box;
  width: 100vw;
  -webkit-justify-content: center;
          justify-content: center;
  bottom: 36px;
  position: absolute;
  list-style-type: none;
}
.ControlDots li {
  height: 20px;
  border-radius: 20px;
  margin: 0 8px;
  width: 20px;
  float: left;
  background-color: white;
}

.ControlDots .slick-active {
  background-color: #ff5900;
  opacity: 1;
}

.OrderModeModalMianDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: whitesmoke;
  /* background-image: url("../../assets/Dine-in-1-bg.png"); */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 300px 16px;
}

.OrderModeModalHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.offerImageKiosk {
  width: 100vw;
  height: 200px;
  object-fit: contain;
  margin-top: 10vh;
}

.swal-content__input {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .OrderModeModalHeader ~ div > img {
    width: 400px !important;
  }
}

.deliveryCaptiontext {
  margin: 0;
  padding: 0;
  font-size: 32px;
  color: red;
  text-align: center;
}
.orderHereCaptiontext {
  margin-top: -5vh;
  padding: 0;
  font-size: 100px;
  color: #ff5500;
  text-align: center;
  
}

.MainRouterDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: none;
  box-sizing: border-box;
}

.RouterDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(/static/media/back.59dfc94e.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.ScreenRenderDiv {
  height: calc(100vh - 620px);
  overflow: hidden;
  width: 100%;
  padding: 0;
  box-lines: border-box;
  padding-bottom: 48px;
}

.BlurBackDiv {
  height: 600px;
  z-index: 90;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    180deg,
    rgb(245, 245, 245, 0.8) 0%,
    transparent 27%,
    transparent 100%
  );
  -webkit-filter: "blur(16px)";
          filter: "blur(16px)";
}

.animateImage {
  height: 360px;
  width: 240px;
  position: absolute;
  top: 12;
  left: 200;
  border-radius: 10px;
}
.animateImage-enter {
  -webkit-transform: scale(2);
          transform: scale(2);
}
.animateImage-exit {
  top: 70vh;
  left: 40vh;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
}

@media only screen
  and (max-width: 768px) {
  .ScreenRenderDiv {
      height: calc(100vh - 325px);
  }

  .BlurBackDiv {
    height: 320px;
  }

  .ScreenRenderDiv .GrabbnGoLogoMainDiv > img{
    height: 24px;
  }

  .ScreenRenderDiv .GrabbnGoLogoMainDiv {
    height: 40px;
  }

  .footer_ph {
    height: 310px !important;
  }


}
.userDetailBackground {
  background-image: url(/static/media/userdetails.cbd16ba7.png);
  padding-top: "25%";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.8;
  /* Preserve aspet ratio */
  width: 100vw;
  height: 100%;
  padding-top: 10vh;
}
.userDetailScreen {
  width: 800px;
  margin: 0 auto;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 1px 1px;
  height: 60%;
  text-align: center;
  padding-top: 40px;
}
input {
  width: 300px;
  margin-left: 140px;
  margin-right: 140px;
  height: 70px;
  padding: 20px;
  font-size: 28px;
  padding: 4px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 500px;
  margin: auto;
}

/*
    Theme: myTheme1
  */
.simple-keyboard.myTheme1 {
  border-radius: 0;
  /* padding: 0; */
  margin-top: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-row .hg-button {
  height: 80px;
  max-width: 160px !important;
  color: white;
  -webkit-animation: none;
          animation: none;
  background: rgba(0, 0, 0, 0.86);
  font-weight: bold;
  font-size: 24px;
}
.hg-functionBtn {
  height: 80px;
  width: 160px !important;
  max-width: 200px;
}
.simple-keyboard.myTheme1 .hg-row .hg-button.hg-standardBtn {
  height: 80px;
  width: 160px !important;
  max-width: 200px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}

.PhoneNoDiv {
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.CountrySelectDiv {
  height: 60px;
  width: 80px;
  float: left;
}

.buttonTheme {
  width: calc(500px / 3);
  box-sizing: border-box;
}

.PhoneInputDiv {
  width: 380px;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  margin: 0;
  display: block;
  letter-spacing: 5px;
}

.PhoneInputDiv::-webkit-input-placeholder {
  letter-spacing: 0;
}

.PhoneInputDiv::-ms-input-placeholder {
  letter-spacing: 0;
}

.PhoneInputDiv::placeholder {
  letter-spacing: 0;
}

.NextButton {
  background: #000000;
  border: 1px solid #888888;
  height: 60px;
  border-radius: 10px;
  width: 200px;
  margin: 60px auto 0;
}

.AEPcheckBox {
  display: inline-block;
  height: 53px;
  width: 53px;
  margin: 0;
  margin-right: 32px;
}
@media only screen and (max-width: 768px) {
  .userDetailBackground > p {
    font-size: 30px !important;
    margin-bottom: 30px !important;
  }

  .userDetailScreen {
    height: 80% !important;
    padding-top: 20px !important;
  }

  .NextButton {
    margin: 20px auto 0 !important;
  }
}

.OrderReviewScreen_checkout_container__FSj2u {
  height: 50vh;
  width: 850px;
  margin: auto;
  margin-top: 2vh;
}
.OrderReviewScreen_applycode_container__1x1OA {
 
  width: 850px;
  margin: auto;
  margin-top: 1vh;
}
.OrderReviewScreen_place_order_button_div__26k5C {
  width: 100%;
  margin: auto;
  margin-top: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.OrderReviewScreen_place_order_button__Qaoh3 {
  background-color: #417505 !important;
  height: 60px !important;
  width: 300px !important;
  border: 1px solid #417505 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-size: 34px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  line-height: 34px !important;
  padding: 12px !important;
}

.OrderReviewScreen_recommender_div__2ySVg {
  position: absolute;
  bottom: 140px;
  left: 0;
  right: 0;
}

.OrderReviewScreen_bottom_panel_div__1N-ta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Recommendation_main_div__2-P2Q {
  background-color: whitesmoke;
  padding: 12px;
  width: 100vw;
  overflow: hidden;
}
.Recommendation_header_div__3SNlh {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
}
.Recommendation_content_div__29U88 {
  display: -webkit-flex;
  display: flex;
  height: 200px;
  width: 100%;
  overflow-x: auto;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
}

.RecommendedCard_main_div__35TnF {
  width: 212px !important;
  padding: 0 !important;
  margin-right: 12px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 200px;
          flex-basis: 200px;
}

.RecommendedCard_card_image__gMvJ0 {
  height: 100px !important;
  width: 100%;
  object-fit: contain !important;
  background: transparent;
}

.RecommendedCard_content_div__aT5N3 {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 14px;
}

.RecommendedCard_pp_div__9EkUA {
  height: 30px !important;
  background-color: #d8d8d8;
  width: 100% !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 4px;
}
.RecommendedCard_location_div__iyBaC {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.RecommendedCard_price_div__1lKLk {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
}
.RecommendedCard_item_detail_div__2TgSq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 4px;
}
.RecommendedCard_item_name_div__jI9ob {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.RecommendedCard_item_desc__2kJm_ {
  font-size: 12px;
  margin-top: 4px;
}
.RecommendedCard_store_logo_div__2gXid {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 8px;
  top: 8px;
  background-color: transparent;
}
.RecommendedCard_store_logo_div__2gXid > img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
.RecommendedCard_item_contains_div__2lDgE {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 8px;
  top: 8px;
  background-color: transparent;
}

