.TokenScreenMainContainer {
  background-image: url("../../assets/userdetails.png");
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-bottom: 12px;
  box-sizing: border-box;
}

.TokenScreenTickContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.phoneText {
  border-radius: 4px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
}

.TokenScreenTokenCardContainer {
  height: 60vh;
  width: 800px;
  margin: auto;
  margin-top: 150px;
  background-color: #ffffff;
  opacity: 0.8;
  overflow: hidden;
  box-sizing: border-box;
}

.TokenCardInfoSection {
  border-bottom: 2px dashed #666666;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.TokenCardInfoSectionLogo {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TokenCardInfoSectionTokenNo {
  display: flex;
  height: 100px;
  width: 126px;
  border-radius: 6px;
  flex-direction: column;
  border: #666666 1px solid;
}

.TokenCardInfoSectionTokenNo .TokenTextDiv {
  background-color: #4a4a4a;
  text-align: center;
  height: 32;
}

.TokenCardOrderDetailSec {
  /* padding-left: 24px 8px; */
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  overflow: auto;
  height: 48%;
}

.TokenCardOrderItem {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.TokenCardOrderItem .Qty {
  flex: 2;
}
.TokenCardOrderItem .NameDetails {
  flex: 13;
}
.TokenCardOrderItem .PrepTimeInfo {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TokenCardOrderItem .PriceInfo {
  flex: 4;
}

.TokenCardExtraChargeDiv {
  overflow: auto;
  padding: 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom: #666666 1px dashed;
}

.TokenCardTotalDiv {
  padding: 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.TokenCardPaymentModeDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: #666666 1px solid;
  padding: 16px;
}

@media only screen and (max-width: 768px) {
  .TokenScreenTickContainer {
    margin: 0 !important;
  }

  .TokenScreenTokenCardContainer {
    width: 90% !important;
    margin: 0 auto !important;
    overflow: auto;
  }
}

.checkout_container_token_screen {
  height: 60vh;
  width: 850px;
  margin: auto;
  margin-top: 32px;
  opacity: 0.8;
}
