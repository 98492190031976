.live_cart_main_div {
  box-sizing: border-box;
  color: white;
}
.cart_header {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.your_cart_text {
  font-size: 30px;
  color: "#FFFFFF";
  margin-left: 12px;
}

.count_badge {
  padding: 2px;
}

.cart_icon {
  font-size: 32px;
  color: "#FFFFFF";
  margin-left: 8px;
}
.cart_amount_text {
  color: "#FFFFFF";
  font-size: 30px;
  font-weight: "600";
}
.cart_amount_heading {
  font-weight: "300";
  margin-right: 4px;
}

.pay_button {
  font-size: 30px;
  color: "#FFFFFF";
  text-shadow: 0 2px 2px #4a4a4a;
  font-weight: "bold";
}

.back_button_div {
  display: flex;
  flex-direction: row;
  width: 100px;
  color: "#F0F8FE";
  align-items: center;
}

.back_button {
  color: "#F0F8FE";
  margin-left: 8px;
  font-size: 32px;
}

.pay_button_div {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.live_cart_pay_button {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin-right: 24px;
  height: 90%;
  min-width: 200px;
}
.pay_div_amount_text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 56px;
  min-width: 180px;
}

.live_cart_pay_button_div {
  border-radius: 10px;
  background-image: linear-gradient(-90deg, #73ba36 0%, #429321 100%);
  display: flex;
  box-sizing: content-box;
  width: 100px;
  justify-content: center;
  align-items: center;
}

.bottom_panel_card_list {
  background: #193c05;
  height: 160px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  flex-wrap: nowrap;
}

.bottom_panel_card_list::-webkit-scrollbar {
  display: none;
}

.live_cart_button_div {
  background-color: #091c01;
  padding: 0 20px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order_mode_switch_div {
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1),
    inset 1px 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order_mode_switch_div > .active_div {
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
}

.active_text {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.inactive_text {
  opacity: 0.5;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
.restart_button {
  float: right;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .BottomPanelCardList {
    height: 90px;
  }

  .BottomPanelPayButtonDiv {
    height: 40px;
  }

  .fav_store_list {
  }

  .fav_store_list .BottomPanelStoreList {
    height: 140px;
  }

  .fav_store_list .BottomPanelStoreList > div {
    width: 120px !important;
    height: 120px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 16px 0px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .my-masonry-grid_column {
    width: 33% !important;
  }

  .trend_card_ph {
    width: 100% !important;
    padding: 5px 10px !important;
  }

  .trend_card {
    /* width: 100% !Important; */
  }

  .card_price > div {
    height: 20px;
    font-size: 16px;
  }

  .card_location {
    font-size: 11px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px;
  }

  .material-icons,
  .material-icons > svg {
    font-size: 14px !important;
  }

  .itemPrice {
    font-size: 14px !important;
    font-weight: 600;
  }

  .card_title {
    font-size: 14px !important;
    margin: 3px 0px;
  }

  .card_ingredient {
    font-size: 11px !important;
  }

  .my-masonry-grid_column img {
    /* width: 100% !important; */
  }

  .BottomPanelAmountText > p,
  .BottomPanelPayButtonDiv p {
    font-size: 18px !important;
  }

  .BottomPanelPayButtonDiv img {
    height: 24px !important;
    width: 24px !important;
  }

  .BottomPanelButton {
    height: 32px;
    margin-top: 2px;
  }

  .LiveCartButtonsDiv {
    padding: 0 10px;
    height: 40px;
  }

  .OrderModeSwitchDiv .ActiveDiv > p,
  .back_btn {
    font-size: 20px !important;
  }

  .OrderModeSwitchDiv > p {
    font-size: 12px;
  }

  .RestartButton img {
    height: 36px !important;
    margin-right: 15px;
    width: 105px !important;
  }
}
