.mainDiv {
  /* The image used */
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.8;
  /* Preserve aspet ratio */
  width: 100%;
  height: 100%;
  padding-top: 200px;
}
.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.startButton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0px;
  height: 140px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #f9a825 30%, #ff9800 90%);
}
.startButtonText {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: white;
}

.loginModal {
  background-color: whitesmoke;
  padding-bottom: 32px;
  width: 50%;
  z-index: 100;
  box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2);
  position: absolute;
  text-align: center;
  top: 20%;
}
.loginModalButton {
  margin-top: 16px;
  width: 50%;
  padding: 12px;
  background: linear-gradient(45deg, #f9a825 30%, #ff9800 90%);
  color: white;
  font-size: 24px;
  font-weight: 700;
}
.modalTitleDiv {
  border-bottom: 1px dashed gray;
}
.ControlDots {
  display: flex !important;
  box-sizing: border-box;
  width: 100vw;
  justify-content: center;
  bottom: 36px;
  position: absolute;
  list-style-type: none;
}
.ControlDots li {
  height: 20px;
  border-radius: 20px;
  margin: 0 8px;
  width: 20px;
  float: left;
  background-color: white;
}

.ControlDots .slick-active {
  background-color: #ff5900;
  opacity: 1;
}
