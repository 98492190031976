.userDetailBackground {
  background-image: url("../../assets/userdetails.png");
  padding-top: "25%";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.8;
  /* Preserve aspet ratio */
  width: 100vw;
  height: 100%;
  padding-top: 10vh;
}
.userDetailScreen {
  width: 800px;
  margin: 0 auto;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 1px 1px;
  height: 60%;
  text-align: center;
  padding-top: 40px;
}
input {
  width: 300px;
  margin-left: 140px;
  margin-right: 140px;
  height: 70px;
  padding: 20px;
  font-size: 28px;
  padding: 4px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 500px;
  margin: auto;
}

/*
    Theme: myTheme1
  */
.simple-keyboard.myTheme1 {
  border-radius: 0;
  /* padding: 0; */
  margin-top: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-row .hg-button {
  height: 80px;
  max-width: 160px !important;
  color: white;
  animation: none;
  background: rgba(0, 0, 0, 0.86);
  font-weight: bold;
  font-size: 24px;
}
.hg-functionBtn {
  height: 80px;
  width: 160px !important;
  max-width: 200px;
}
.simple-keyboard.myTheme1 .hg-row .hg-button.hg-standardBtn {
  height: 80px;
  width: 160px !important;
  max-width: 200px;
  flex-grow: 0;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}

.PhoneNoDiv {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CountrySelectDiv {
  height: 60px;
  width: 80px;
  float: left;
}

.buttonTheme {
  width: calc(500px / 3);
  box-sizing: border-box;
}

.PhoneInputDiv {
  width: 380px;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  margin: 0;
  display: block;
  letter-spacing: 5px;
}

.PhoneInputDiv::placeholder {
  letter-spacing: 0;
}

.NextButton {
  background: #000000;
  border: 1px solid #888888;
  height: 60px;
  border-radius: 10px;
  width: 200px;
  margin: 60px auto 0;
}

.AEPcheckBox {
  display: inline-block;
  height: 53px;
  width: 53px;
  margin: 0;
  margin-right: 32px;
}
@media only screen and (max-width: 768px) {
  .userDetailBackground > p {
    font-size: 30px !important;
    margin-bottom: 30px !important;
  }

  .userDetailScreen {
    height: 80% !important;
    padding-top: 20px !important;
  }

  .NextButton {
    margin: 20px auto 0 !important;
  }
}
