.main_div {
  width: 212px !important;
  padding: 0 !important;
  margin-right: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
}

.card_image {
  height: 100px !important;
  width: 100%;
  object-fit: contain !important;
  background: transparent;
}

.content_div {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 14px;
}

.pp_div {
  height: 30px !important;
  background-color: #d8d8d8;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
}
.location_div {
  display: flex;
  align-items: center;
}
.price_div {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.item_detail_div {
  display: flex;
  flex-direction: column;
  padding: 4px;
}
.item_name_div {
  display: flex;
  justify-content: space-between;
}

.item_desc {
  font-size: 12px;
  margin-top: 4px;
}
.store_logo_div {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 8px;
  top: 8px;
  background-color: transparent;
}
.store_logo_div > img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
.item_contains_div {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 8px;
  top: 8px;
  background-color: transparent;
}
