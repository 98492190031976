.main_div {
  background-color: whitesmoke;
  width: 850px;
  padding: 24px;
  text-align: center;
  margin: 24px auto 0;
  opacity: 0.7;
}
.rating_div {
  display: flex;
  justify-content: space-between;
  padding: 48px;
}
.main_div > p {
  font-size: 28px;
}

.modal_body {
  position: fixed;
  background-color: white;
  top: 40%;
  width: 50vw;
  left: 25vw;
  right: 25vw;
  text-align: center;
  border-radius: 12px;
}
.modal_body > p {
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 32px;
  margin-top: 32px;
  text-transform: none !important;
}

.submit_button {
  background-color: #f55000 !important;
  width: 200px !important;
  margin-bottom: 32px !important;
}
