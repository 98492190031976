.QunatityPickerMainDiv {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 0;
  height: 35px;
  width: 120px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.QunatityPickerMainDiv div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  font-size: 27px;
}

.QunatityPickerButtonLeftDiv {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.QunatityPickerButtonRightDiv {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
