.MainRouterDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: none;
  box-sizing: border-box;
}

.RouterDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.ScreenRenderDiv {
  height: calc(100vh - 620px);
  overflow: hidden;
  width: 100%;
  padding: 0;
  box-lines: border-box;
  padding-bottom: 48px;
}

.BlurBackDiv {
  height: 600px;
  z-index: 90;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    180deg,
    rgb(245, 245, 245, 0.8) 0%,
    transparent 27%,
    transparent 100%
  );
  filter: "blur(16px)";
}

.animateImage {
  height: 360px;
  width: 240px;
  position: absolute;
  top: 12;
  left: 200;
  border-radius: 10px;
}
.animateImage-enter {
  transform: scale(2);
}
.animateImage-exit {
  top: 70vh;
  left: 40vh;
  transform: scale(0.2);
}

@media only screen
  and (max-width: 768px) {
  .ScreenRenderDiv {
      height: calc(100vh - 325px);
  }

  .BlurBackDiv {
    height: 320px;
  }

  .ScreenRenderDiv .GrabbnGoLogoMainDiv > img{
    height: 24px;
  }

  .ScreenRenderDiv .GrabbnGoLogoMainDiv {
    height: 40px;
  }

  .footer_ph {
    height: 310px !important;
  }


}