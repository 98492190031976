.checkout_container {
  height: 50vh;
  width: 850px;
  margin: auto;
  margin-top: 2vh;
}
.applycode_container {
 
  width: 850px;
  margin: auto;
  margin-top: 1vh;
}
.place_order_button_div {
  width: 100%;
  margin: auto;
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.place_order_button {
  background-color: #417505 !important;
  height: 60px !important;
  width: 300px !important;
  border: 1px solid #417505 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-size: 34px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 34px !important;
  padding: 12px !important;
}

.recommender_div {
  position: absolute;
  bottom: 140px;
  left: 0;
  right: 0;
}

.bottom_panel_div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
