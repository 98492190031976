body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  /* user-select: none; */
  scroll-behavior: smooth;
}
p {
  margin: 0;
  padding: 0;
  text-transform: capitalize !important;
  letter-spacing: 0.5px;
}

button {
  border: none;
}
