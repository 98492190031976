.CouponInputKeyboardDiv {
  width: 600px;
}
.CouponInputKeyboardDiv .simple-keyboard {
  max-width: 600px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.InputBox {
  height: 70px;
  width: 500px;
  border: 2px solid #979797;
  border-radius: 10px;
  margin: 0 50px;
  box-sizing: border-box;
  font-size: 28px;
  padding: 0 20px;
}
.CouponInputKeyboardDiv .myTheme1 {
  background-color: transparent;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.CouponInputKeyboardDiv .simple-keyboard.myTheme1 .hg-row {
  margin: 0;
}
.CouponInputKeyboardDiv .simple-keyboard.myTheme1 .hg-row .hg-button {
  height: 80px;
  width: 60px;
  color: white;
  background: rgba(0, 0, 0, 0.86);
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}
