.main {
  margin-top: 8px;
}
.input_div {
  display: flex;
  flex: 1;
  max-width: 90%;
  border: 1px solid #ff5500;
  border-radius: 4px;
}
.container_div{
  /*display: flex;*/
  flex: 1;
  max-width: 90%;
  margin-left: 5%;
}
.input {
  flex: 1;
  padding-left: 12px;
}

.apply_button {
  border-radius: 0 !important;
  margin-top: 10% !important;
  width: 82%;
  height: 60px !important;
}
