.bg_input_modal {
  width: 80vw;
  margin: auto;
  background-color: white;
  border-radius: 16px;
  padding: 30px 16px;
  position: fixed;
  top: 25%;
  margin: 0 10vw;
}
.cust_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title_text {
  font-size: 32px;
  letter-spacing: 1px;
}
.textinput_default {
  width: 80%;
  border: #1c4995;
  margin: auto;
  text-align: center;
  font-size: 28px;
  letter-spacing: 1px;
}

.keyboard1 {
  /* background: rgba(0, 0, 0, 0.86) !important; */
  max-width: 800px;
  min-height: 300px;
}

.simple-keyboard.myTheme .hg-row .hg-button {
  /* color: white; */
  animation: none;
  /* background: rgba(0, 0, 0, 0.86) !important; */
  font-weight: bold;
  font-size: 24px;
  height: 55px;
  font-family: Open Sans;
}

.hg-button-bksp {
  flex: 2 !important;
}

.hg-functionBtn.hg-button-enter {
  flex: 1;
  max-width: 500px;
}

.hg-functionBtn.hg-button-space {
  flex: 1;
  max-width: 500px;
}
.gate-list {
  padding: auto;
}

.gate-list li {
  width: 100%;
  list-style-type: none;
  float: left;
  width: 20%;
  height: 60px;
  border: 1px dotted #ccc;
  padding: 12px;
  font-size: 24px;
  background-color: #f55000;
  color: white;
  font-weight: bold;
}

.gate-sel-div {
  margin-right: 4%;
  margin-bottom: 3%;
}
