.StoreCardMainCard {
  height: 100%;
  width: 100%;
  margin-right: 8px;
  box-sizing: border-box;
  background: white;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.store_card_name_text{
  text-align: center;

}